import classNames from 'classnames';
import ISOCountries from 'i18n-iso-countries';
import _forOwn from 'lodash/forOwn';
import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import FieldLink from '../../field/FieldLink/FieldLink';
import './member-list.scss';

/**
 * Component for rendering a list of members with a filter.
 */
const MemberList = (props) => {
    ISOCountries.registerLocale(require('../../../utils/i18n-iso-countries-en.json'));

    // Client-side code, only executed in the browser.
    // Toggles the countries based on the selector.
    useEffect(() => {
        const countrySelect = document.querySelector(
            'select[name=country-select-widget]',
        );
        let countries = document.querySelectorAll('div.country-member');

        if (countrySelect && countries && countries.length > 0) {
            countrySelect.addEventListener('change', function (e) {
                let activeCountry = document.querySelector(
                    'div.country-member.country--' + e.target.value,
                );

                if (activeCountry) {
                    for (let country of countries) {
                        country.setAttribute('aria-hidden', true);
                        country.classList.add('d-none');
                    }
                    activeCountry.setAttribute('aria-hidden', false);
                    activeCountry.classList.remove('d-none');
                } else {
                    for (let country of countries) {
                        country.setAttribute('aria-hidden', false);
                        country.classList.remove('d-none');
                    }
                }
            });
        }
    }, []);

    // Group members by country.
    let groups = {};
    props.members.map(({ node }, i) => {
        if (node.r.association && node.r.association.country) {
            groups[node.r.association.country] =
                groups[node.r.association.country] || [];
            groups[node.r.association.country].push(node);
        }
        return node
    });
    // Convert the grouped countries to an array so it can be rendered using React.
    let countries = [];
    _forOwn(groups, (members, country_code) => {
        countries.push({
            members: members,
            country_code: country_code,
            country_name: ISOCountries.getName(country_code, 'en', {
                select: 'official',
            }),
        });
    });
    // Sort by country name.
    countries.sort((a, b) => {
        return a.country_name.localeCompare(b.country_name);
    });

    return (
        <div className="member-list">
            <div className="country-select">
                <label>
                    Country:
                    <select
                        name="country-select-widget"
                        id="country-select-widget">
                        <option value="0">- Select -</option>
                        {countries.map((country, i) => {
                            const countryKey = `country-option-${i}`
                            return (
                                <option key={countryKey} value={country.country_code}>
                                    {country.country_name}
                                </option>
                            );
                        })}
                    </select>
                </label>
            </div>

            <div className="info-box">
                <i className="bi bi-info-circle"></i> To find further details on
                a particular member please click on its name.
            </div>

            {countries.map((country, i) => {
                const countryKey = `country-${i}`
                return (
                    <div
                        key={countryKey}
                        className={classNames([
                            'country-member',
                            'country--' + country.country_code,
                        ])}>
                        <div className="country-member--top country-flag">
                            <div
                                className={classNames([
                                    'country-flag--image',
                                    'country-flag--' + country.country_code,
                                ])}></div>
                            <h2 className="country-member--title">
                                {country.country_name}
                            </h2>
                        </div>
                        <div className="paragraph-type--links">
                            <ul className="country-member--list">
                                {country.members.map((member, j) => {
                                    const memberkey = `member-${j}`
                                    return (
                                        <li key={memberkey}>
                                            <i className="bi bi-arrow-right-short"></i>
                                            <FieldLink
                                                link={{
                                                    uri: member.path.alias,
                                                }}>
                                                {member.title}
                                            </FieldLink>
                                            {_has(
                                                member,
                                                'r.association.name_en',
                                            ) && member.show_name && (
                                                <div className="member-name-en">
                                                    {
                                                        member.r.association
                                                            .name_en
                                                    }
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

MemberList.propTypes = {
    members: PropTypes.array,
};

export default MemberList;
